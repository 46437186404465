<template>
  <b-button :variant="variant" @click="$emit('click')">{{ text }}</b-button>
</template>

<script>
export default {
  props: {
    text: { type: String, default: () => 'anything' },
    variant: { type: String, default: 'primary' }
  }
}
</script>
