<template>
  <b-container fluid>
    <div class="wrapper p-3 p-md-5">
      <report-summary
        :report="report"
        :sup-order-id="order.orderId"
        :sup-status="order.status"
        v-show="!isLoading"
      />

      <div v-if="isCompleted" class="my-5" v-show="!isLoading">
        <a
          class="link float-right my-2"
          variant="info"
          href="/Supplemental_Report.pdf"
          target="_blank"
        >
          <b-icon icon="pdf-filled" style="color: red" />
          View Report
        </a>
      </div>

      <tradeline-component
        ref="tradeline"
        :items="items"
        :order-id="order.orderId"
        class="my-5"
        v-show="!isLoading"
      />

      <div v-show="!isLoading">
        <div class="bg-blue text-white text-center w-100 py-2">
          Data received from FI
        </div>
        <b-table
          striped
          hover
          :items="dataReceivedItems"
          :fields="dataReceivedFields"
          bordered
        >
          <template #head(account_name)="data"
            ><span class="d-inline">{{ data.label }}</span>
            <info-component v-if="isCompleted" class="d-inline ml-2" />
          </template>
        </b-table>
      </div>

      <button-component
        v-if="order"
        :status="order.status"
        @assign-to-processor="assignToProcessor"
        @generate-report="generateReport"
      />
      <b-overlay
        :show="isLoading"
        :no-wrap="isSubmitting"
        :fixed="isSubmitting"
      ></b-overlay>
    </div>
    <div class="my-5 py-3" />
  </b-container>
</template>
<script>
import TradelineComponent from '../Base/TradelineComponent.vue'
import ReportSummary from '../common/ReportSummary.vue'
import ButtonComponent from '../common/ButtonComponent.vue'
import { apiService } from '../../scripts/services'
import InfoComponent from '../common/InfoComponent.vue'
export default {
  components: {
    TradelineComponent,
    ReportSummary,
    ButtonComponent,
    InfoComponent
  },
  name: 'Birchwoood',
  props: {},
  computed: {
    isCompleted() {
      return (this.order?.status ?? '').toLowerCase() === 'completed'
    }
  },
  methods: {
    assignToProcessor() {
      this.isSubmitting = true
      apiService.assignToProcessor(this.order.orderId).then(() => {
        this.$router.go(0)
      })
    },
    generateReport() {
      this.isSubmitting = true
      apiService.generateReport(this.order.orderId).then(() => {
        this.$router.go(0)
      })
    }
  },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      order: {},
      report: {},
      supplementOrder: {},
      fields: [
        {
          key: 'show_details',
          label: '',
          class: 'text-left'
        },
        {
          key: 'ecoa',
          label: '',
          class: 'text-left'
        },
        {
          key: 'whose',
          label: '',
          class: 'text-left'
        },
        {
          key: 'creditor',
          label: '',
          class: 'text-left'
        },
        {
          key: 'datereported',
          label: '',
          class: 'text-left'
        },
        {
          key: 'dateopeneddla',
          label: '',
          class: 'text-left'
        },
        {
          key: 'hclacctype',
          label: '',
          class: 'text-left'
        },
        {
          key: 'balanceterms',
          label: '',
          class: 'text-left'
        },
        {
          key: 'pastdue',
          label: '',
          class: 'text-left'
        },
        {
          key: 'morev',
          label: '',
          class: 'text-left'
        },
        {
          key: 't30',
          label: '',
          class: 'text-left'
        },
        {
          key: 't60',
          label: '',
          class: 'text-left'
        },
        {
          key: 't90',
          label: '',
          class: 'text-left'
        },
        {
          key: 'statussource',
          label: '',
          class: 'text-left'
        }
      ],
      items: [],
      selected: 'd',
      options: [{ value: 'd', text: 'Update balance' }],
      dataReceivedFields: [
        {
          key: 'account_name',
          label: 'Account name',
          class: 'text-left'
        },
        {
          key: 'account_number',
          label: 'Account number',
          class: 'text-left'
        },
        {
          key: 'balance',
          label: 'Balance',
          class: 'text-left'
        }
      ],
      dataReceivedItems: [
        {
          account_name: 'Jhon Doe',
          account_number: 88888888,
          balance: '$22000'
        }
      ]
    }
  },
  mounted() {
    this.isLoading = true
    apiService
      .getOrders(this.$route.params.id)
      .then((r) => {
        this.order = r
        this.report = r.report
        this.items = r.tradelines
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bg-blue {
  background-color: #003366;
}
.text-blue {
  color: #003366;
}
.thvertical {
  writing-mode: vertical-rl !important;
  text-orientation: upright !important;
}
.wfour {
  width: 4%;
}
.wfive {
  width: 5%;
}
.wsix {
  width: 6%;
}
.weight {
  width: 8%;
}
.wten {
  width: 10%;
}
.weleven {
  width: 11%;
}
.wtwelve {
  width: 12%;
}
.wfourteen {
  width: 14%;
}

table > thead > tr > th {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #fff;
  /* background-color: #17a2b8 !important; */
}
table > thead > tr:first-of-type {
  /* display: none; */
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  background-color: #0033660f;
}
.table th,
.table td {
  font-size: 0.86rem;
}
</style>
