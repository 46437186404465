<template>
  <div>
    <b-form>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive="sm"
        headVariant="tableHeadSupp"
        bodyVariant="tableBodySupp"
        borderless
        outlined
        small
      >
        <template #head(ecoa)>
          <div class="text-center"><span class="thvertical">ECOA</span></div>
        </template>
        <template #head(whose)>
          <div class="text-center"><span class="thvertical">WHOSE</span></div>
        </template>
        <template #head(datereported)>
          <b-row align="center" justify="center">
            <b-col>DATE <br />REPORTED</b-col>
          </b-row>
        </template>
        <template #head(dateopeneddla)>
          <b-row align="center" justify="center">
            <b-col>DATE <br />OPENEND</b-col>
          </b-row>
          <hr />
          <b-row align="center" justify="center">
            <b-col>DLA</b-col>
          </b-row>
        </template>
        <template #head(hclacctype)>
          <b-row align="center" justify="center">
            <b-col>HIGH <br />CREDIT<br />OR LIMIT</b-col>
          </b-row>
          <hr />
          <b-row align="center" justify="center">
            <b-col>ACC TYPE</b-col>
          </b-row>
        </template>
        <template #head(balanceterms)>
          <b-row align="center" justify="center">
            <b-col>BALANCE </b-col>
          </b-row>
          <hr />
          <b-row align="center" justify="center">
            <b-col>TERMS</b-col>
          </b-row>
        </template>
        <template #head(pastdue)>
          <b-row align="center" justify="center">
            <b-col>PAST<br />DUE </b-col>
          </b-row>
        </template>
        <template #head(morev)>
          <b-row align="center" justify="center">
            <b-col>MO<br />REV </b-col>
          </b-row>
        </template>
        <template #head(statussource)>
          <b-row align="center" justify="center">
            <b-col>STATUS<br />SOURCE </b-col>
          </b-row>
        </template>

        <template v-slot:cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
            :disabled="disableEdit"
          >
          </b-form-checkbox>
        </template>
        <template v-slot:cell(creditor)="row">
          <div class="text-left mini-text">
            <span style="text-decoration: underline" class="text-info">
              {{ row.item.accountNumber }}</span
            >
            <br />
            {{ row.item.number }}
            <br />
            {{ row.item.directory }}
            <br />
            {{ row.item.add1 }}
            <br />
            {{ row.item.add2 }}
            <br />
            {{ row.item.add3 }}
          </div>
        </template>
        <template v-slot:cell(datereported)="row">
          <b class="text-center"> {{ row.item.datereported }}</b>
        </template>
        <template v-slot:cell(dateopeneddla)="row">
          <b class="text-center"> {{ row.item.dateopened }}</b>
          <br />
          <b>{{ row.item.dla }}</b>
        </template>
        <template v-slot:cell(hclacctype)="row">
          <b class="text-center"> {{ row.item.hcl }}</b>
          <br />
          <b>{{ row.item.acctype }}</b>
        </template>
        <template v-slot:cell(balanceterms)="row">
          <b class="text-center"> {{ row.item.balance }}</b>
          <br />
          <b>{{ row.item.terms }}</b>
        </template>
        <template v-slot:cell(pastdue)="row">
          <b class="text-center"> {{ row.item.pastdue }}</b>
        </template>
        <template v-slot:cell(morev)="row">
          <b class="text-center"> {{ row.item.morev }}</b>
        </template>
        <template v-slot:cell(t30)="row">
          <b class="text-center"> {{ row.item.t30 }}</b>
        </template>
        <template v-slot:cell(t60)="row">
          <b class="text-center"> {{ row.item.t60 }}</b>
        </template>
        <template v-slot:cell(t90)="row">
          <b class="text-center"> {{ row.item.t90 }}</b>
        </template>
        <template v-slot:cell(statussource)="row">
          <b class="text-center"> {{ row.item.status }}</b>
          <br />
          <b class="text-info text-center"> {{ row.item.source }}</b>
        </template>
        <template v-slot:row-details="row">
          <b-card>
            <b-row class="mb-2 text-left">
              <b-col sm="5">
                <b>Comments:</b>
                <b-form-textarea
                  :disabled="disableEdit"
                  id="textarea-small"
                  size="sm"
                  v-model="row.item.comment"
                  placeholder="Small textarea"
                ></b-form-textarea>
              </b-col>
              <b-col>
                <b>Supplement Type:</b>
                <b-form-select
                  :disabled="disableEdit"
                  v-model="row.item.type"
                  :options="options"
                  size="sm"
                ></b-form-select
              ></b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    orderId: { type: String, default: () => '' },
    items: { type: Array, default: () => [] }
  },
  computed: {
    disableEdit() {
      return !!this.orderId
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'show_details',
          label: 'SELECT'
        },
        {
          key: 'ecoa',
          label: 'ECOA'
        },
        {
          key: 'whose',
          label: 'WHOSE'
        },
        {
          key: 'creditor',
          label: 'CREDITOR'
        },
        {
          key: 'datereported',
          label: 'DATE REPORTED'
        },
        {
          key: 'dateopeneddla',
          label: ''
        },
        {
          key: 'hclacctype',
          label: ''
        },
        {
          key: 'balanceterms',
          label: ''
        },
        {
          key: 'pastdue',
          label: 'PAST DUE'
        },
        {
          key: 'morev',
          label: ''
        },
        {
          key: 't30',
          label: '30'
        },
        {
          key: 't60',
          label: '60'
        },
        {
          key: 't90',
          label: '90+'
        },
        {
          key: 'statussource',
          label: ''
        }
      ],
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' }
      ]
    }
  }
}
</script>
