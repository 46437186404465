<template>
  <div class="mini-text">
    <table>
      <tr>
        <td class="bg-blue text-white p-1">Applicant</td>
        <td class="p-1" style="width: 40%">
          {{ secondary ? report.coApplicantName : report.applicantName }}
        </td>
        <td class="bg-blue text-white p-1">SSN</td>
        <td class="p-1" style="width: 30%">
          {{ secondary ? report.coApplicantSSN : report.applicantSSN }}
        </td>
      </tr>
    </table>
    <table style="width: 100%">
      <tr>
        <td class="bg-blue text-white p-1" colspan="3">Credit Score</td>
      </tr>
      <tr>
        <td class="p-1 col-4">
          {{
            secondary ? report.coApplicantBureauTU : report.applicantBureauTU
          }}
        </td>
        <td class="p-1 col-4">
          {{
            secondary ? report.coApplicantBureauXP1 : report.applicantBureauXP1
          }}
        </td>
        <td class="p-1 col-4">
          {{
            secondary ? report.coApplicantBureauXP2 : report.applicantBureauXP2
          }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    report: { type: Object },
    secondary: { type: Boolean, default: () => false }
  }
}
</script>
