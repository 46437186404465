<template>
  <div v-if="report">
    <b-row>
      <b-col>
        <div class="shadowed p-1 mb-2">
          <div class="d-flex p-1">
            <h6 class="text-blue text-left mr-5">
              <b-icon icon="pin" class="mr-2"></b-icon>Report #
              {{ report.reportId }}
            </h6>
            <h6 v-if="supOrderId" class="text-blue text-left mr-5">
              <b-icon icon="tag" class="mr-2"></b-icon>Order #
              {{ supOrderId }}
            </h6>
            <h6 v-if="supStatus" class="text-blue text-left">
              <b-icon :icon="getStatusIcon" class="mr-2"></b-icon>Status -
              {{ supStatus }}
            </h6>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <div>
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center p-1"
            >
              <strong>Lender :</strong>
              <span>Birchwood Mortgage</span>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center p-1"
            >
              <strong> Address :</strong>
              <span>North Conway, NH</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <summary-info :report="report" />
      </b-col>

      <b-col cols="12" md="4">
        <summary-info :report="report" secondary />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SummaryInfo from './SummaryInfo.vue'
export default {
  components: { SummaryInfo },
  props: {
    supStatus: { type: String },
    supOrderId: { type: String },
    report: { type: Object }
  },
  computed: {
    STATUS() {
      return (this.supStatus ?? '').toLowerCase()
    },
    getStatusIcon() {
      switch (this.STATUS) {
        case 'completed':
          return 'check-circle' // 'check2-All'
        case 'data-processed':
          return 'arrow-clockwise'
        default:
          return 'arrow-repeat'
      }
    }
  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bg-blue {
  background-color: #003366;
}
.text-blue {
  color: #003366;
}
.thvertical {
  writing-mode: vertical-rl !important;
  text-orientation: upright !important;
}
.wfour {
  width: 4%;
}
.wfive {
  width: 5%;
}
.wsix {
  width: 6%;
}
.weight {
  width: 8%;
}
.wten {
  width: 10%;
}
.weleven {
  width: 11%;
}
.wtwelve {
  width: 12%;
}
.wfourteen {
  width: 14%;
}

table > thead > tr > th {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #fff;
  /* background-color: #17a2b8 !important; */
}

table > thead > tr > th {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #fff;
  background-color: #17a2b8 !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  background-color: #0033660f;
}
.table th,
.table td {
  font-size: 0.86rem;
}
</style>
