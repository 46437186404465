<template>
  <div v-if="status">
    <div class="float-right mb-4" v-if="status === 'create'">
      <BaseButton
        variant="outline-secondary"
        class="mx-2"
        text="Cancel"
        @click="goBack"
      />
      <BaseButton class="bg-blue" text="Submit" @click="$emit('new-order')" />
    </div>
    <div class="float-right mb-4" v-if="status.toLowerCase() === 'inprogress'">
      <!-- <BaseButton variant="outline-secondary" class="mx-2" text="Cancel" />
      <router-link to="/orders">
        <BaseButton class="bg-blue" text="I AM IN PROGRESS BUTTON" />
      </router-link> -->
    </div>
    <div
      class="float-right mb-4"
      v-if="status.toLowerCase() === 'data-processed'"
    >
      <BaseButton
        class="bg-blue mx-3"
        text="Assign to Processor"
        @click="$emit('assign-to-processor')"
      />
      <BaseButton
        class="bg-blue"
        text="Generate Supplement Report"
        @click="$emit('generate-report')"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue'
export default {
  props: {
    status: { type: String }
  },
  components: { BaseButton },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
