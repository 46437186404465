<template>
  <div>
    <button id="show-btn" @click="showModal" class="d-inline-block myinfobtn">
      Info
    </button>
    <b-modal
      ref="my-modal"
      hide-footer
      title="Summary"
      class="sm"
      small
      scrollable
    >
      <div class="mini-text">
        <h6 class="bg-blue text-white p-1">ACCOUNT DETAILS</h6>
        <b-table :items="accountDetails" striped borderless outlined small>
        </b-table>

        <div class="mt-5" />

        <h6 class="bg-blue text-white p-1">ACCOUNT HISTORY</h6>
        <b-table :items="accountHistory" striped borderless outlined small>
          <template #cell(particulars)="data">
            raind
            <div class="text-left">{{ data }}</div>
          </template>
        </b-table>
      </div>
      <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >Close Me</b-button
      > -->
      <b-overlay :show="showBusy" no-wrap></b-overlay>
    </b-modal>
  </div>
</template>

<style scoped>
.myinfobtn {
  padding: 0rem 1.2rem;
  background: green;
  border-radius: 8px;
  color: #fff;
  font-size: 0.8rem;
  text-decoration: none;
  opacity: 0.8;
  border: none;
}
.myinfobtn:hover {
  cursor: pointer;
  opacity: 1;
}
</style>

<script>
import { apiService } from '../../scripts/services'
export default {
  methods: {
    showModal() {
      // this.modalKey = new Date().getMilliseconds()
      this.$refs['my-modal'].show()
      this.loadData()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    loadData() {
      this.showBusy = true
      apiService.getAccountNumber().then((r) => {
        const tbl = []
        Object.keys(r).forEach((k) => {
          tbl.push({ particulars: k, value: r[k] })
        })
        this.accountDetails = tbl

        apiService.getAccountHistory().then((b) => {
          this.accountHistory = b
          setTimeout(() => {
            this.showBusy = false
          }, 1200)
        })
      })
    }
  },
  data() {
    return {
      showBusy: false,
      // modalKey: new Date().getMilliseconds(),
      accountHistory: [],
      accountDetails: []
    }
  }
}
</script>
